@mixin vuejs-datepicker-theme($background, $color: null) {
  $color: if($color, $color, yiq($background));
  $higlighted-bg: rgba-to-hex(rgba($background, .15), #fff);
  $higlighted-color: yiq($higlighted-bg);

  .vdp-datepicker__calendar .selected {
    background: $background !important;
    color: $color !important;
  }

  .vdp-datepicker__calendar .cell.day.highlighted {
    background: $higlighted-bg;
    color: $higlighted-color;
  }
}

@mixin vuejs-datepicker-dark-theme($background, $color: null) {
  $color: if($color, $color, yiq($background));
  $higlighted-bg: rgba-to-hex(rgba($background, .15), #383b40);
  $higlighted-color: yiq($higlighted-bg);

  .vdp-datepicker__calendar .selected {
    background: $background !important;
    color: $color !important;
  }

  .vdp-datepicker__calendar .cell.day.highlighted {
    background: $higlighted-bg;
    color: $higlighted-color;
  }
}
