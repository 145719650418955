@mixin vue-simple-calendar-event-variant($parent, $background, $opacity: .15) {
  $parent: if($parent != null, '.cv-event-#{$parent}.cv-event', '.cv-event');

  #{$parent} {
    background: rgba($background, $opacity) !important;
  }
}

@mixin vue-simple-calendar-theme($background) {
  @include vue-simple-calendar-event-variant(null, $background);

  .cv-day.draghover {
    box-shadow: inset 0 0 0.0 .125rem $background !important;
  }

  .cv-day.today {
    background: rgba($background, .05) !important;
  }
}

@mixin vue-simple-calendar-dark-theme($background) {
  @include vue-simple-calendar-event-variant(null, $background, .5);

  .cv-day.draghover {
    box-shadow: inset 0 0 0.0 .125rem $background !important;
  }

  .cv-day.today {
    background: rgba($background, .25) !important;
  }
}
