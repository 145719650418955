@mixin vue-slider-variant($background, $color: null) {
  $color: if($color, $color, yiq($background));

  .vue-slider-process,
  .vue-slider-mark-step-active {
    background: $background !important;
  }
  .vue-slider-dot-tooltip-inner {
    background: $background !important;
    color: $color !important;
  }
}

@mixin material-vue-slider-variant($background, $color: null) {
  $color: if($color, $color, yiq($background));

  .vue-slider-process,
  .vue-slider-mark-step-active,
  .vue-slider-dot-handle {
    background: $background !important;
  }
  .vue-slider-dot-handle {
    box-shadow: none !important;
  }
  .vue-slider-dot-tooltip-inner {
    background: $background !important;
    color: $color !important;
  }
}

@mixin vue-slider-theme($background, $color: null) {
  .vue-slider-primary {
    @include vue-slider-variant($background, $color);
  }
}

@mixin material-vue-slider-theme($background, $color: null) {
  .vue-slider-primary {
    @include material-vue-slider-variant($background, $color);
  }
}
